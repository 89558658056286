import React from "react";
import Placeholder from "../../components/Store/index";

const index = () => {
  return (
    <>
      <Placeholder />
    </>
  );
};

export default index;

export const Head = () => <title>Store</title>;
